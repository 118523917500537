import React from 'react';
import { IconsUI, ui } from '../config/imports';

function IconsExample() {
  function mapObject(obj, path = '') {
    const result = {};

    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(result, mapObject(obj[key], `${path}${key}.`));
      } else {
        result[`${path}${key}`] = obj[key];
      }
    }

    return result;
  }

  const mappedUi = mapObject(ui);
  console.log(mappedUi);

  const icons = Object.keys(mappedUi).map((key) => {
    console.log(`ui.${key}`);
    return (
      <IconsUI
        info={mappedUi[key]}
        clsnm='m-3 hov-green'
        tooltip={`ui.${key}`}
      />
    );
  });

  return (
    <div className='wrappedefpage'>
      <h4>Icons</h4>

      <div className='icons'>{icons}</div>
    </div>
  );
}

export default IconsExample;
