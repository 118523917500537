import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { logo, menu, IconsUI, GlobalContext } from '../config/imports';

export default function NavBar() {
  const { setSection, checkLogin } = useContext(GlobalContext);

  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findToggleItem = () => {
    return menu.navbar.find((item) => item.toggle);
  };

  const findItems = () => {
    const items = menu.navbar
      .filter((item) => item.toggle === false)
      .filter(
        (item) => item.onlyLogged === false || item.onlyLogged === logged,
      );
    return items;
  };
  const createDivWithInfo = (item) => {
    if (!item) return null;
    return (
      <Dropdown.Toggle
        variant=''
        className={`d-flex flex-row align-items-center ${item.addClass}`}
        id='navbar-dropdown-toggle'
      >
        <IconsUI
          info={item.icon}
          clsnm='hov-green'
          tooltip={item.tooltip}
        />
        {item.showText && <span className='sp-menu-text'>{item.text}</span>}
      </Dropdown.Toggle>
    );
  };

  const toggleMenu = createDivWithInfo(findToggleItem());

  return (
    <div className='navbar-100'>
      <div className='navbar'>
        <div className='navbar-logo'>
          <a
            href='/'
            rel='noreferrer'
          >
            <img
              src={logo}
              alt='carreon'
            />
          </a>
        </div>
        <div className='navbar-menu'>
          <Dropdown>
            {toggleMenu}
            <Dropdown.Menu>
              {findItems().map((item) => {
                return (
                  <Dropdown.Item
                    key={item.name}
                    href={item.path ? item.path : '#'}
                    className={`${item.addClass}`}
                    onClick={() => {
                      if (item.setSection) {
                        setSection(item.section);
                      }
                    }}
                  >
                    {item.icon && (
                      <IconsUI
                        info={item.icon}
                        clsnm='hov-green'
                        tooltip={item.tooltip}
                      />
                    )}
                    {item.showText && (
                      <span className='sp-menu-text'>{item.text}</span>
                    )}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
