import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { conf, GlobalContext, LoadingDots } from '../config/imports';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [failedTryLogin, setFailedTryLogin] = useState(false);
  const [message, setMessage] = useState('');
  const [inputType, setInputType] = useState('password');

  const { loading, setLoading, navigate, login } = useContext(GlobalContext);

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    const response = await login({ email, password });
    console.log(response.data);

    const { success, message } = response.data;
    console.log({ success, message, response });
    if (success) {
      setMessage('');
      setFailedTryLogin(false);
      setLoading(false);
      navigate('/app');
    } else {
      setMessage(message);
      setFailedTryLogin(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    setFailedTryLogin(true);
  }, [email, password]);

  if (loading)
    return (
      <>
        <LoadingDots />
      </>
    );

  return (
    <div className='wrapper'>
      <div className='w-80 wpmax-400 bg-light p-3 rounded'>
        <div className='text-center  my-5'>
          <a href='/'>
            <img
              src={conf.images.logo_fc_hz_color}
              className='wp-250 wmax-80'
              alt='carreon'
            />
          </a>
        </div>

        <form
          action=''
          className='w-100'
        >
          <Form.Label
            htmlFor='email'
            className='fc-dark fs-12'
          >
            E-mail
          </Form.Label>
          <Form.Control
            type='email'
            id='email'
            autoComplete='email'
            name='email'
            aria-describedby='email'
            onChange={({ target: { value } }) => setEmail(value)}
            className='mb-4'
          />

          <Form.Label
            htmlFor='password'
            className='fc-dark fs-12'
          >
            Password
          </Form.Label>
          <div className='d-flex flex-row align-items-start '>
            <Form.Control
              type={inputType}
              id='password'
              autoComplete='current-password'
              onChange={({ target: { value } }) => setPassword(value)}
            />
            {inputType === 'password' ? (
              <FaRegEye
                className='ms-2 mt-2 svg14 svg-grey'
                onClick={() => setInputType('text')}
              />
            ) : (
              <FaRegEyeSlash
                className='ms-2 mt-2 svg14 svg-grey'
                onClick={() => setInputType('password')}
              />
            )}
          </div>

          <p className='c-red fs-10 fw-500 mt-2'>
            {failedTryLogin ? message : ''}
          </p>

          <div className='d-flex flex-row justify-content-around mt-5 mb-2'>
            <Link to='/recover'>
              <Button
                className='no_under'
                variant='link'
                size='sm'
              >
                Esqueci a senha
              </Button>
            </Link>
            <Button
              variant='primary'
              onClick={(event) => handleLogin(event)}
              size='sm'
            >
              Efetuar Login
            </Button>
          </div>

          {/* <div className='text-center mt-4'>
            <Link to='/register'>
              <Button
                className='no_under'
                variant='link'
              >
                Primeiro acesso? Cadastre-se
              </Button>
            </Link>
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default Login;
