import React from 'react';
import { ContainerBox, CardBox } from '../config/imports';

export default function Saude() {
  const cards = [
    {
      title: 'Proteção Jurídica para Seu Direito à Saúde',
      maintext:
        'Auxiliamos pacientes a ter acesso a tratamentos essenciais, enfrentando negativas e atrasos no SUS e planos de saúde, com atuação especializada em todo o Brasil.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Apoio Jurídico para Cirurgias e Tratamentos Urgentes',
      maintext:
        'Prestamos suporte jurídico para distribuir ações que permitam a obtenção de liminares para cirurgias e tratamentos oncológicos em casos de urgência.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Assessoria Jurídica para Home Care',
      maintext:
        'Atuamos em processos para viabilizar internações domiciliares, assegurando que os pacientes recebam o tratamento adequado no conforto de suas casas, conforme a legislação.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Judicialização para Tratamentos Inovadores',
      maintext:
        'Oferecemos apoio jurídico para ações que busquem o acesso a tratamentos inovadores, como exames genéticos e cirurgias robóticas, conforme as previsões legais.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Ação Jurídica para Obtenção de Liminares em Procedimentos Vitais',
      maintext:
        'Distribuímos ações para obtenção de liminares que possibilitem o acesso imediato a procedimentos vitais, respeitando os direitos do paciente à saúde com agilidade.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Assessoria Jurídica para Terapias de Autistas e PCDs',
      maintext:
        'Oferecemos suporte jurídico para ajudar no acesso legal a terapias multidisciplinares, como ABA e Denver, superando negativas, filas de espera e outros entraves prejudiciais.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Suporte Jurídico para Medicamentos de Alto Custo',
      maintext:
        'Auxiliamos pacientes na distribuição de ações para acesso a medicamentos de alto custo, como quimioterapia oral, cannabis medicinal e outros, superando negativas e atrasos.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Atuação Imediata em Urgências e Emergências de Saúde',
      maintext:
        'Prestamos assistência jurídica em casos de urgência vital, propondo ações rápidas para viabilizar o acesso a cuidados essenciais e tratamentos emergenciais.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Plantão Jurídico 24h para Emergências de Saúde',
      maintext:
        'Nosso plantão jurídico está disponível 24 horas por dia para atender casos de urgência, oferecendo orientação e suporte jurídico em processos emergenciais de saúde.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
  ];

  return (
    <div>
      <ContainerBox
        main={
          <div className=''>
            <div className='container-1100 p-3'>
              <div className='p-5 bw-bg-overlay-stronger'>
                <h4 className='my-5 fc-gold fw-600 '>Direito da Saúde</h4>

                <h5 className='my-3'>
                  Proteção Integral para Pacientes em Todo o Brasil
                </h5>

                <p>
                  O Carreon Advocacia é referência nacional na defesa dos
                  direitos à saúde, garantindo que pacientes em todo o Brasil
                  tenham acesso aos tratamentos e cuidados de que necessitam,
                  seja pelo SUS ou planos de saúde privados.
                </p>

                <p>
                  Atuamos de forma incisiva em casos de negativas de cobertura,
                  atrasos na liberação de procedimentos e fornecimento de
                  medicamentos vitais, judicializando quando necessário.
                </p>

                <h5 className='mt-5 pt-3 mb-3'>Judicialização da Saúde</h5>

                <p>
                  Milhares de brasileiros enfrentam diariamente negativas de
                  tratamento. Nosso escritório se especializou em assegurar que
                  os planos de saúde e o SUS cumpram suas obrigações legais,
                  garantindo o acesso imediato a cirurgias de alta complexidade,
                  medicamentos inovadores e tratamentos urgentes.
                </p>

                <p>
                  Oferecemos uma atuação estratégica para a obtenção de
                  liminares, assegurando que os direitos dos pacientes sejam
                  respeitados em tempo hábil.
                </p>

                <p>
                  Trabalhamos com qualquer causa em que foram negados direitos
                  aos pacientes, como é o caso de negativa para tratamento de
                  doenças graves, autistas e PCDs, cirurgias e procedimentos
                  inovadores, tratamentos oncológicos, cirurgias com a
                  utilização de robótica, home care e internações domiciliares,
                  judicialização de medicamentos de alto custo, como nos casos
                  de quimioterapias orais e cannabis medicinal, exames genéticos
                  e cirurgias para tratamento de ceratocone.
                </p>

                <p>
                  Nosso escritório atua em regime de plantão, atuando de forma
                  rápida e objetiva em casos de urgência e emergência, pois
                  estamos preparados para propor ações emergenciais para
                  garantir o tratamento de urgência em casos vitais. A
                  prioridade é sempre o bem-estar e a proteção da saúde de
                  nossos clientes, assegurando que seus direitos sejam
                  preservados sem demora.
                </p>
              </div>
              <div
                className='container-1100 mt-4 mb-5 d-flex flex-row fs-10 flex-wrap'
                id='test-smooth'
              >
                {cards.map((card, index) => (
                  <CardBox
                    key={`card-deck_direito_saude${index}`}
                    title={card.title}
                    subtitle={card.subtitle}
                    image={card.image}
                    header={card.header}
                    maintext={card.maintext}
                    classes={card.classes}
                    link1={card.link1}
                  />
                ))}
              </div>

              <div className='p-5 bw-bg-overlay-stronger'>
                <p className='pt-3'>
                  <span className='fw-500 fs-16'>
                    Você ou algum familiar enfrenta negativas de tratamento ou
                    cobranças indevidas?
                  </span>{' '}
                  Entre em contato agora para garantir seus direitos de forma
                  ágil e eficaz. Seja qual for o seu problema, estamos prontos
                  para ajudar você a garantir seus direitos com dedicação e
                  eficiência.
                </p>
              </div>
            </div>
          </div>
        }
        navbar={true}
        sidebar={false}
        footer={true}
        overlayMain={false}
        classMain='bgsaude'
      />
    </div>
  );
}
