import React from 'react';
import { conf, IconsUI, ui, utils, logo, FaleConosco } from '../config/imports';

export default function Footer() {
  const linkWhatsapp = utils.create_link_whatsapp(conf.contato.whatsapp);

  return (
    <footer>
      <div className='py-2'>
        <div className='d-flex flex-row justify-content-between container-1100 gocol950'>
          <div className='d-flex flex-column align-content-start flex-wrap'>
            <img
              src={logo}
              alt='logo'
              className='w-90 wpmax-300'
            />
            <span className='mt-2'>
              <a
                className='hov-yellow'
                href={`mailto:${conf.contato.email}`}
                target='_blank'
                rel='noreferrer'
              >
                <IconsUI
                  info={ui.social.email}
                  clsnm='me-2'
                />
                {conf.contato.email}
              </a>
            </span>
            <span className=''>
              <a
                className='hov-green'
                href={linkWhatsapp}
                target='_blank'
                rel='noreferrer'
              >
                <IconsUI
                  info={ui.social.whatsapp}
                  clsnm='me-2'
                />
                {conf.contato.whatsapp}
              </a>
            </span>
          </div>

          <div className='d-flex flex-row justify-content-between text-center gocol630'>
            <div className='d-flex flex-column wpmin-200 mt-3'>
              <h5>Redes Sociais</h5>
              <p className='d-flex flex-row justify-content-center align-items-center'>
                <a
                  href={`https://www.linkedin.com/in/${conf.contato.linkedin}`}
                  rel='noreferrer'
                  target='_blank'
                >
                  <span>Linkedin</span>

                  <IconsUI
                    info={ui.social.linkedin}
                    clsnm='ms-2'
                  />
                </a>
              </p>
              <p className='d-flex flex-row justify-content-center align-items-center'>
                <a
                  href={`https://www.instagram.com/${conf.contato.instagram}`}
                  rel='noreferrer'
                  target='_blank'
                >
                  <span>Instagram</span>
                  <IconsUI
                    info={ui.social.instagram}
                    clsnm='ms-2'
                  />
                </a>
              </p>
            </div>
            <div className='d-flex flex-column wpmin-200 mt-3'>
              <h5>Links</h5>
              <p>
                <a
                  href='/'
                  rel='noreferrer'
                >
                  Home
                </a>
              </p>
                <FaleConosco
                  typeButton={false}
                  textButton='Fale Conosco'
                  pClass='fc-gold mouse-pointer'
                />
              <p>
                <a
                  href='/privacidade'
                  rel='noreferrer'
                  target='_blank'
                >
                  Privacidade
                </a>
              </p>
            </div>
            <div className='d-flex flex-column wpmin-200 mt-3'>
              <h5>Especialidades</h5>
              <p>
                <a
                  href='/direito-medico'
                  rel='noreferrer'
                >
                  Direito Médico
                </a>
              </p>
              <p>
                <a
                  href='/direito-saude'
                  rel='noreferrer'
                >
                  Direito da Saúde
                </a>
              </p>
              <p>
                <a
                  href='/direito-digital'
                  rel='noreferrer'
                >
                  Direito Digital
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
