import Card from 'react-bootstrap/Card';

export default function CardBox(cardObject) {
  const card = () => {
    return (
      <div className='m-2 cardbox-main'>
        <Card
          className={cardObject.classes.card}
          bg='dark'
          text='white'
        >
          {cardObject.image ? (
            <Card.Img
              variant='top'
              src={cardObject.image}
              className={cardObject.classes.image}
            />
          ) : (
            ''
          )}
          {cardObject.header ? (
            <Card.Header className={cardObject.classes.header}>
              {cardObject.header}
            </Card.Header>
          ) : (
            ''
          )}

          <Card.Body className={cardObject.classes.body}>
            {cardObject.title ? (
              <Card.Title className={cardObject.classes.title}>
                {cardObject.title}
              </Card.Title>
            ) : (
              ''
            )}
            {cardObject.subtitle ? (
              <Card.Subtitle className={cardObject.classes.subtitle}>
                {cardObject.subtitle}
              </Card.Subtitle>
            ) : (
              ''
            )}

            {cardObject.maintext ? (
              <Card.Text className={cardObject.classes.text}>
                {cardObject.maintext}
              </Card.Text>
            ) : (
              ''
            )}
          </Card.Body>
          {cardObject.link1 || cardObject.link2 ? (
            <Card.Footer className={cardObject.classes.footer}>
              {cardObject.link1 ? (
                <Card.Link
                  className={cardObject.classes.link}
                  href={cardObject.link1.link}
                >
                  {cardObject.link1.text}
                </Card.Link>
              ) : (
                ''
              )}

              {cardObject.link2 ? (
                <Card.Link
                  className={cardObject.classes.link}
                  href={cardObject.link2.link}
                >
                  {cardObject.link2.text}
                </Card.Link>
              ) : (
                ''
              )}
            </Card.Footer>
          ) : (
            ''
          )}
        </Card>
      </div>
    );
  };

  return card();
}
