import { IconsUI } from '../config/imports';

  export default function LinkButton({ item, text = '', clsnm= '', iconClass = '' }) {
  return (
    <a
      href={item.href}
      target='_blank'
      rel='noreferrer'
      className={`btn outline-secondary w-btn-hover ${clsnm}`}
    >
      <IconsUI info={item.type}  clsnm={iconClass} />
      {
        text ? <span className='d-flex flex-row ms-2'>{text}</span> : null
      }
    </a>
  );
}
