export default function HomeApresentacao() {
  return (
    <div className='container-1100 my-5 p-2'>
      <div className='p-5 bg-dark'>
        <p className='fw-600 fs-18 ff-soft'>Quem Somos</p>
        <p className='fa-justify'>
          Nossa trajetória é marcada pela busca contínua por justiça e proteção
          dos direitos nas áreas de saúde e no universo digital. Com mais de 20
          anos de experiência, sob a liderança do Dr. Evandro Carreon,
          combinamos a tradição jurídica com a inovação tecnológica, sempre com
          foco em soluções personalizadas e humanizadas para nossos clientes.
        </p>
        <p className='fa-justify'>
          Nosso propósito é transformar o cenário do Direito Médico, da Saúde e
          Digital no Brasil, consolidando nossa posição como uma das principais
          referências nacionais nessas áreas. Com uma consultoria especializada
          e multidisciplinar, oferecemos a segurança jurídica que médicos,
          pacientes e empresas precisam em um mundo cada vez mais conectado e
          globalizado.
        </p>
        <p className='fa-justify'>
          Nossa missão é entregar soluções jurídicas de excelência, atuando em
          setores complexos como Direito Médico, Direito da Saúde e Direito
          Digital. Cada uma dessas áreas possui suas particularidades: o Direito
          Médico protege os profissionais da saúde, o Direito da Saúde garante o
          acesso a tratamentos adequados, e o Direito Digital assegura
          conformidade e segurança no ambiente virtual. Nossa abordagem une
          tecnologia, ética e um olhar humanizado, sempre em busca da justiça e
          da defesa dos direitos de nossos clientes.
        </p>
        <p className='fa-justify'>
          Temos a visão de ser uma referência confiável e inovadora em Direito
          Médico, Direito da Saúde e Direito Digital, reconhecida pela nossa
          atuação ética, humana e disruptiva. Nosso compromisso é contribuir
          ativamente para a justiça e o bem-estar de nossos clientes, oferecendo
          soluções jurídicas eficazes e adaptadas às suas necessidades reais,
          sem perder a sensibilidade e o compromisso com a justiça.
        </p>

        <p className='fa-justify'>
          <b>Valores:</b> Ética e Transparência, Inovação Tecnológica,
          Humanização no Atendimento e Excelência Jurídica, sempre com foco em
          resultados sólidos e soluções legais precisas.
        </p>
      </div>
    </div>
  );
}
