import React from 'react';
import { ContainerBox, CardBox } from '../config/imports';

export default function Medico() {
  const cards = [
    {
      title: 'Advocacia Preventiva e Compliance',
      maintext:
        'Soluções customizadas para revisão de contratos e adequação de prontuários, termos de consentimento e contratos de agendamento, garantindo conformidade e segurança.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Consultoria Contínua',
      maintext:
        'Assegurar que médicos, clínicas e hospitais estejam atualizados e em conformidade com a legislação sanitária e médica vigente.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Treinamento Especializado de Equipes',
      maintext:
        'Oferecemos treinamentos personalizados para equipes médicas e administrativas, capacitando-as a lidar com desafios legais e administrativos, sempre dentro das normas.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Documentação Médica Completa e Personalizada',
      maintext:
        'Elaboramos documentos médicos personalizados, garantindo que estejam adequadamente ajustados às normas jurídicas, assegurando uma defesa robusta em possíveis litígios.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Defesa Jurídica em Casos de Responsabilidade Civil',
      maintext:
        'Oferecemos defesa técnica e estratégica, utilizando perícias especializadas para proteger a reputação e os direitos de nossos clientes. Representamos médicos perante os Conselhos Regionais e Federal de Medicina (CRM/CFM), sempre com agilidade e eficiência.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Recuperação de Crédito e Assistência Financeira',
      maintext:
        'Atuamos de forma estratégica e rápida na recuperação de créditos devidos por pacientes inadimplentes e na cobrança de valores devidos por planos de saúde e pelo SUS, garantindo que nossos clientes sejam remunerados corretamente e dentro dos prazos estabelecidos.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Atendimento Especializado e de Plantão',
      maintext:
        'Nosso escritório oferece um atendimento de plantão para ações emergenciais, garantindo uma resposta rápida em casos de urgência, como liminares para garantir o tratamento de pacientes.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
  ];

  return (
    <div>
      <ContainerBox
        main={
          <div className=''>
            <div className='container-1100 p-3'>
              <div className='p-5 bw-bg-overlay-stronger'>
                <h4 className='my-5 fc-gold fw-600 '>Direito Médico</h4>

                <h5 className='my-3'>
                  Proteção Jurídica para Médicos, Clínicas e Hospitais
                </h5>

                <p>
                  No Carreon Advocacia, oferecemos um atendimento altamente
                  especializado e personalizado para médicos, clínicas e
                  hospitais. Nossa assessoria abrange desde a advocacia
                  preventiva até a defesa em processos complexos, garantindo
                  segurança jurídica em cada detalhe das operações de nossos
                  clientes.
                </p>

                <p>
                  Atuamos com foco total na proteção dos profissionais de saúde,
                  sempre em conformidade com as mais rigorosas normas do setor.
                </p>
              </div>

              <div
                className='container-1100 mt-4 mb-5 d-flex flex-row fs-10 flex-wrap'
                id='test-smooth'
              >
                {cards.map((card, index) => (
                  <CardBox
                    key={`card-deck_direito_medico${index}`}
                    title={card.title}
                    subtitle={card.subtitle}
                    image={card.image}
                    header={card.header}
                    maintext={card.maintext}
                    classes={card.classes}
                    link1={card.link1}
                  />
                ))}
              </div>
            </div>
          </div>
        }
        navbar={true}
        sidebar={false}
        footer={true}
        overlayMain={true}
        classMain='bgmedico'
      />
    </div>
  );
}
