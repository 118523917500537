import { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  GlobalContext,
  ContainerBox,
  api,
  endpoint,
  IconsUI,
  ui,
  Loading,
  conf,
} from '../config/imports';

export default function Dashboard() {
  const { checkLogin, navigate } = useContext(GlobalContext);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  // const [user, setUser] = useState({});

  useEffect(() => {
    const status = checkLogin();
    if (!status) {
      navigate('/login');
    } else {
      const data = async () => {
        const response = await api.post(endpoint.leads.read);
        if (response.status === 200) {
          setLeads(response.data);
        }
      };
      data();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  const delete_item = async (event, codigo) => {
    event.preventDefault();
    const delete_lead = async () => {
      try {
        setLoading(true);
        await api.post(endpoint.leads.delete, {
          codigo,
        });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
      const upd = !updated;
      setUpdated(upd);
    };

    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você deseja deletar esse lead? Essa ação não pode ser desfeita!',
      icon: 'warning',
      background: conf.colors.grafitte,
      color: conf.colors.grey,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonColor: conf.colors.bs.green,
      confirmButtonText: 'Sim, deletar',
      iconColor: conf.colors.bs.yellow,
      denyButtonColor: conf.colors.bs.red,
      denyButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Ok, deletado!',
          icon: 'success',
          confirmButtonColor: conf.colors.bs.green,
          confirmButtonText: 'Ok',
          background: conf.colors.grafitte,
          color: conf.colors.grey,
          iconColor: conf.colors.bs.green,
        });
        delete_lead();
      } else if (result.isDenied) {
        Swal.fire({
          text: 'Tudo bem, vou manter aqui',
          icon: 'info',
          confirmButtonColor: conf.colors.bs.yellow,
          confirmButtonText: 'Ok',
          background: conf.colors.grafitte,
          color: conf.colors.grey,
          iconColor: conf.colors.bs.yellow,
        });
      }
    });
  };

  return (
    <div>
      <ContainerBox
        main={
          <>
            <div className='container-1100 p-3'>
              <p className='f-soft f-10 fc-gray'>Painel</p>

              <div>
                <h4 className='my-3 fc-gold fw-600'>Leads</h4>

                {leads.length > 0 ? (
                  <table className='table table-dark table-striped fs-10'>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Telefone</th>
                        <th>Mensagem</th>
                        <th>Data</th>
                        <th>ID</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {leads.map((lead, index) => (
                        <tr key={lead.codigo}>
                          <td>{lead.NAME}</td>
                          <td>{lead.EMAIL}</td>
                          <td>{lead.PHONE}</td>
                          <td>{lead.MESSAGE}</td>
                          <td>
                            {new Date(lead.CREATED_AT).toLocaleDateString(
                              'pt-BR',
                            )}{' '}
                            {new Date(lead.CREATED_AT).toLocaleTimeString(
                              'pt-BR',
                            )}
                          </td>
                          <td className=''>#{lead.ID}</td>
                          <td>
                            {loading ? (
                              <Loading />
                            ) : (
                              <IconsUI
                                info={ui.action.delete.trash}
                                click={(e) => delete_item(e, lead.CODIGO)}
                                clsnm='svg-danger'
                                tooltip='Excluir'
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center'>
                    <p className='f-soft'>Nenhum lead cadastrado</p>
                  </div>
                )}
              </div>
            </div>
          </>
        }
        navbar={true}
        sidebar={false}
        footer={false}
      />
    </div>
  );
}
