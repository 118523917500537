import React from 'react';
import { ContainerBox, CardBox } from '../config/imports';

export default function Digital() {

  // crete json

  // Crimes Cibernéticos: Recuperação e Defesa Jurídica através da atuação junto a plataformas digitais para recuperação rápida de contas invadidas, com soluções administrativas e judiciais.

  // Responsabilização de Invasores: Ações civis e criminais contra infratores, buscando compensação por danos.

  // Reparação de Danos à Reputação: Defesa estratégica para restaurar a imagem afetada por crimes digitais.

  // Assessoria Jurídica para Influenciadores Digitais

  // Contratos Personalizados: Elaboração de contratos robustos com marcas e anunciantes, prevenindo litígios.

  // Orientação em Publicidade Digital: Assessoria sobre as exigências legais e éticas em parcerias e promoções.

  // Proteção de Imagem e Propriedade Intelectual: Criação de políticas de uso de imagem e conteúdo para evitar apropriação indevida e uso não autorizado.

//   Conformidade com a LGPD

// 			Consultoria Especializada: Adequação completa às normas da Lei Geral de Proteção de Dados (LGPD), prevenindo sanções legais.

// 			Defesa em Casos de Violação: Representação jurídica em processos por violação de privacidade, minimizando impactos financeiros e à reputação.

// Resolução de Litígios Digitais

// 			Ações Contra Plataformas Digitais: Movemos ações judiciais ou administrativas para garantir a recuperação de contas suspensas ou encerradas injustamente.

// 			Mediação e Conciliação: Sempre que possível, buscamos acordos extrajudiciais para resolução de disputas digitais.

  const cards = [
    {
      title: 'Crimes Cibernéticos',
      maintext:
        'Recuperação e Defesa Jurídica através da atuação junto a plataformas digitais para recuperação rápida de contas invadidas, com soluções administrativas e judiciais.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Responsabilização de Invasores',
      maintext:
        'Ações civis e criminais contra infratores, buscando compensação por danos.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Reparação de Danos à Reputação',
      maintext:
        'Defesa estratégica para restaurar a imagem afetada por crimes digitais.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Contratos Personalizados',
      maintext:
        'Elaboração de contratos robustos com marcas e anunciantes, prevenindo litígios.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Orientação em Publicidade Digital',
      maintext:
        'Assessoria sobre as exigências legais e éticas em parcerias e promoções.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Proteção de Imagem e Propriedade Intelectual',
      maintext:
        'Criação de políticas de uso de imagem e conteúdo para evitar apropriação indevida e uso não autorizado.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Conformidade com a LGPD',
      maintext:
        'Adequação completa às normas da Lei Geral de Proteção de Dados (LGPD), prevenindo sanções legais.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Defesa em Casos de Violação da LGPD',
      maintext:
        'Representação jurídica em processos por violação de privacidade, minimizando impactos financeiros e à reputação.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Ações Contra Plataformas Digitais',
      maintext:
        'Movemos ações judiciais ou administrativas para garantir a recuperação de contas suspensas ou encerradas injustamente.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
    {
      title: 'Mediação e Conciliação',
      maintext:
        'Sempre que possível, buscamos acordos extrajudiciais para resolução de disputas digitais.',
      classes: {
        card: 'bw-cardbox bg-bluer-transp ',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
    },
  ];
  
  return (
    <div>
      <ContainerBox
        main={
          <div className=''>
            <div className='container-1100 p-3'>
              <div className='p-5 bw-bg-overlay-stronger'>
                <h4 className='my-5 fc-gold fw-600 '>Direito Digital</h4>

                <h5 className='my-3'>
                Assessoria Jurídica Especializada para Consumidores, Empresas e Influenciadores
                </h5>

                <p>
                No Carreon Advocacia, oferecemos assessoria jurídica altamente especializada para consumidores, empresas e influenciadores, com foco exclusivo no Direito Digital.
                </p>

                <p>
                Nossa atuação vai além do básico, fornecendo soluções jurídicas diferenciadas para garantir a proteção dos direitos digitais dos nossos clientes, tanto no âmbito administrativo quanto judicial.
                </p>

              </div>

              <div
                className='container-1100 mt-4 mb-5 d-flex flex-row fs-10 flex-wrap'
                id='test-smooth'
              >
                {cards.map((card, index) => (
                  <CardBox
                    key={`card-deck_direito_medico${index}`}
                    title={card.title}
                    subtitle={card.subtitle}
                    image={card.image}
                    header={card.header}
                    maintext={card.maintext}
                    classes={card.classes}
                    link1={card.link1}
                  />
                ))}
              </div>

            </div>
          </div>
        }
        navbar={true}
        sidebar={false}
        footer={true}
        overlayMain={true}
        classMain='bgdigital'
      />
    </div>
  );
}
