import {
  ContainerBox,
  CardBox,
  Quem,
  HomeApresentacao,
  paciente_atendimento,
  direito_medico,
  direito_digital,
} from '../config/imports';

export default function Home() {
  const areas = [
    {
      title: 'Direito Médico',
      subtitle:
        'Proteção Jurídica e Assessoria Especializada para Médicos, Clínicas e Hospitais',
      image: direito_medico,
      header: 'Medicina',
      maintext: `Oferecemos assessoria jurídica completa e personalizada para médicos, clínicas e hospitais, tanto na advocacia preventiva quanto na defesa em processos judiciais e administrativos.`,
      classes: {
        card: 'bw-cardbox',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
      link1: {
        text: 'Saiba Mais',
        link: '/direito-medico',
      },
    },
    {
      title: 'Direito da Saúde',
      subtitle: 'Proteção Integral para Pacientes em Todo o Brasil',
      image: paciente_atendimento,
      header: 'Saúde',
      maintext: `Estamos comprometidos em lutar pelos seus direitos à saúde, assegurando que você receba o atendimento e os tratamentos médicos necessários, seja pelo Sistema Único de Saúde (SUS) ou pelos Planos de Saúde privados, de forma ágil e sem barreiras.`,
      classes: {
        card: 'bw-cardbox',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
      link1: {
        text: 'Saiba Mais',
        link: '/direito-saude',
      },
    },
    {
      title: 'Direito Digital',
      subtitle:
        'Assessoria Jurídica Especializada para Consumidores, Empresas e Influenciadores',
      image: direito_digital,
      header: 'Digital',
      maintext: `Nossa atuação no Direito Digital é focada exclusivamente em fornecer assessoria jurídica de excelência para consumidores, empresas e influenciadores digitais.`,
      classes: {
        card: 'bw-cardbox',
        header: 'fc-gold w-100 pt-2',
        footer: 'fs-10',
      },
      link1: {
        text: 'Saiba Mais',
        link: '/direito-digital',
      },
    },
  ];

  return (
    <div>
      {}
      <ContainerBox
        main={
          <>
            <div className='bg-banner bgjustice'>
              <div className='bw-bg-overlay-stronger'>
                <Quem />
              </div>
            </div>

            <HomeApresentacao />

            <div
              className='container-1100 my-3 bw-section-cards'
              id='test-smooth'
            >
              {areas.map((area, index) => (
                <CardBox
                  key={`card-deck_areas${index}`}
                  title={area.title}
                  subtitle={area.subtitle}
                  image={area.image}
                  header={area.header}
                  maintext={area.maintext}
                  classes={area.classes}
                  link1={area.link1}
                />
              ))}
            </div>

            <div
              className='container-1100 my-3 p-5 bw-bg-overlay-stronger'
              id='test-smooth'
            >
              <h3 className='fc-gold ff-soft fw-600 py-3'>
                Por que entrar em contato com o Carreon Advocacia
              </h3>

              <p>
                Você está enfrentando negativas de tratamento por parte do seu
                plano de saúde ou dificuldades com o SUS?
              </p>

              <p>
                Precisa de ajuda jurídica para lidar com processos éticos,
                responsabilidade civil médica ou disputas contratuais em seu
                consultório, clínica ou hospital?
              </p>

              <p>
                Tem dúvidas sobre a organização da documentação, revisão de
                prontuários ou confecção de documentos médicos, como contratos e
                termos de consentimento, para garantir que seu consultório,
                clínica ou hospital esteja operando de forma segura e dentro da
                lei?
              </p>

              <p>
                Ou você é consumidor, influenciador digital ou uma empresa que
                está enfrentando problemas com invasões de contas nas redes
                sociais, uso indevido de sua imagem ou conteúdo, ou deseja
                adequar-se às exigências da LGPD?
              </p>

              <h5 className='my-5 fc-gold'>
                Não espere o problema se agravar. Entre em contato agora para
                receber um atendimento jurídico rápido e eficiente,
                especializado em Direito Médico e Direito da Saúde e Direito
                Digital!
              </h5>

              <p>
                Nossa equipe está pronta para oferecer a melhor solução para sua
                necessidade, em qualquer parte do Brasil.
              </p>
            </div>
          </>
        }
        navbar={true}
        sidebar={false}
        footer={true}
      />
    </div>
  );
}
