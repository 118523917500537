import { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { conf, GlobalContext } from '../config/imports';

export default function FaleConosco({
  typeButton = true,
  textButton = 'Fale agora com um especialista',
  pClass = '',
}) {
  const { createLead } = useContext(GlobalContext);
  const [show, setShow] = useState(false);

  const [form, setForm] = useState({
    email: '',
    whatsapp: '',
    nome: '',
    assunto: '',
    termos: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleForm = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const handleSend = async () => {
    if (
      form.email === '' ||
      form.whatsapp === '' ||
      form.nome === '' ||
      form.assunto === '' ||
      !form.termos
    ) {
      Swal.fire({
        title: 'Ops!',
        text: 'Você precisa preencher todos os campos e concordar com os termos de uso',
        confirmButtonColor: conf.colors.bs.green,
        confirmButtonText: 'Ok, entendi!',
        background: conf.colors.grafitte,
        color: conf.colors.grey,
        iconColor: conf.colors.bs.green,
      });
      return;
    } else {
      await createLead({
        name: form.nome,
        email: form.email,
        phone: form.whatsapp,
        message: form.assunto,
      });
      Swal.fire({
        title: 'Obrigado!',
        text: 'Seus dados foram enviados com sucesso para um especialista. Em breve entraremos em contato.',
        confirmButtonColor: conf.colors.bs.gray[600],
        confirmButtonText: 'Ok',
        background: conf.colors.grafitte,
        color: conf.colors.grey,
        iconColor: conf.colors.bs.green,
      });
      setForm({
        email: '',
        whatsapp: '',
        nome: '',
        assunto: '',
      });
      handleClose();
    }
  };

  return (
    <div className=''>
      {typeButton ? (
        <button
          className='btn btn-primary'
          onClick={handleShow}
        >
          {textButton}
        </button>
      ) : (
        <p
          className={pClass}
          onClick={handleShow}
        >
          {textButton}
        </p>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        data-bs-theme='dark'
      >
        <Modal.Header closeButton>
          <Modal.Title>Fale conosco agora</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type='email'
                placeholder='nome@exemplo.com'
                value={form.email}
                autoFocus
                id='email'
                onChange={(e) => handleForm(e)}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Whatsapp</Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='(00) 00000-0000'
                value={form.whatsapp}
                id='whatsapp'
                onChange={(e) => handleForm(e)}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type='text'
                placeholder='Nome completo'
                value={form.nome}
                required
                id='nome'
                onChange={(e) => handleForm(e)}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Assunto</Form.Label>
              <Form.Control
                as='textarea'
                required
                value={form.assunto}
                rows={5}
                id='assunto'
                onChange={(e) => handleForm(e)}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Check
                type='checkbox'
                required
                checked={form.termos}
                id='termos'
                label='Li e concordo com os termos de uso'
                onChange={(e) => setForm({ ...form, termos: e.target.checked })}
              />
            </Form.Group>
          </Form>
          <div>
            <p className='fs-10'>
              Ao clicar em <b>Solicitar especialista</b>, você concorda com os
              nossos{' '}
              <a
                href='/privacidade'
                target='_blank'
                rel='noreferrer'
              >
                termos de uso e política de privacidade
              </a>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Desistir
          </Button>
          <Button
            variant='primary'
            onClick={handleSend}
          >
            Solicitar especialista
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
