import React from 'react';

export default function Loading(class_op) {
  return (
    <div className={`text-center w-100 p-3 ${class_op}`}>
      <div
        className='spinner-border c-cyan'
        role='status'
      >
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  );
}
