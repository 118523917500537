// import { convertToHtml } from 'mammoth';

function create_link_whatsapp(phone_number) {
  const base = 'https://api.whatsapp.com/send?phone=55';
  const phone = phone_number.length > 0 ? phone_number.replace(/\D+/g, '') : '';
  return `${base}${phone}`;
}

function create_link_linkedin(username) {
  return `https://www.linkedin.com/in/${username}`;
}

function create_link_instagram(username) {
  return `https://www.instagram.com/${username}`;
}

// function parseHtmlToJson(html) {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(html, 'text/html');
//   const paragraphs = [];

//   doc.body.childNodes.forEach((node) => {
//     if (node.nodeName === 'P') {
//       paragraphs.push({ text: node.innerHTML, class: 'paragraph' });
//     } else if (node.nodeName === 'H4') {
//       paragraphs.push({ text: node.innerHTML, class: 'subtitle' });
//     }
//   });

//   return paragraphs;
// }

// function generateHtmlFromJson(paragraphs) {
//   return paragraphs
//     .map((item) => {
//       if (item.class === 'subtitle') {
//         return `<h4 class="${item.class}">${item.text}</h4>`; // Renderizar como subtítulo
//       }
//       return `<p class="${item.class}">${item.text}</p>`; // Renderizar como parágrafo
//     })
//     .join(''); // Juntar todos os elementos HTML em uma string
// }

// async function convertDocxToHtml(inputFilePath) {
//   try {
//     // Converter DOCX para HTML
//     const { value: html } = await convertToHtml({ path: inputFilePath });
//     // Analisar o HTML e gerar o JSON
//     const paragraphs = parseHtmlToJson(html);
//     // Gerar HTML a partir do JSON
//     const generatedHtml = generateHtmlFromJson(paragraphs);
//     return generatedHtml; // Retornar o HTML gerado
//   } catch (error) {
//     console.error('Erro ao converter o arquivo DOCX:', error);
//     throw error; // Lançar o erro para tratamento posterior
//   }
// }

export { create_link_whatsapp, create_link_linkedin, create_link_instagram };
