import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { conf } from '../config/imports';

export default function LoadingDots(class_op) {
  return (
    <div
      className={`wrapper ${class_op}`}
    >
      <img
        src={conf.images.logo_fe_hz_color}
        className='my-3 w-60 wpmax-200'
        alt='logo'
      />
      <ThreeDots
        visible={true}
        height='80'
        width='80'
        color={conf.colors.yellow}
        radius='9'
        ariaLabel='three-dots-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
      <p className='mt-4 mb-2 fs-10  softxt'>Aguarde...</p>
    </div>
  );
}
