import { ui } from './ui';

const images = {
  carreon01nobg: require(`../assets/img/carreon_01_nobg.png`),
  carreon01: require(`../assets/img/carreon_01.jpg`),
  carreon02: require(`../assets/img/carreon_02.jpg`),
  justicebg: require(`../assets/img/justicebg.jpg`),
  logo_512: require(`../assets/img/logo_512.png`),
  logo_fc_hz_color: require(`../assets/img/logo_fc_hz_color.png`),
  logo_fc_sq_color: require(`../assets/img/logo_fc_sq_color.png`),
  logo_fe_hz_color: require(`../assets/img/logo_fe_hz_color.png`),
  logo_fe_sq_color: require(`../assets/img/logo_fe_sq_color.png`),
  autista_terapia: require(`../assets/img/autista-terapia.jpg`),
  direito_digital: require(`../assets/img/direito-digital.jpg`),
  direito_medico: require(`../assets/img/direito-medico.jpg`),
  medico_analisando: require(`../assets/img/medico-analisando.jpg`),
  paciente_atendimento: require(`../assets/img/paciente-atendimento.jpg`),
  reuniao_medicos: require(`../assets/img/reuniao-medicos.jpg`),
};

const colors = {
  black: '#000000',
  darkest: '#02071b',
  darker: '#070d24',
  dark: '#0d142f',
  grafitte: '#393e46',
  blue: '#3a4776',
  bluer: '#007fd4',
  lightBlue: '#007fd4',
  yellow: '#f3c82e',
  gold: '#d1bb57',
  gray: '#cccccc',
  grey: '#f0f0f0',
  white: '#ffffff',
  bs: {
    blue: '#1e337c',
    indigo: '#6610f2',
    purple: '#6f42c1',
    pink: '#d63384',
    red: '#aa0000',
    orange: '#fd7e14',
    yellow: '#ffbb00',
    green: '#2cbe00',
    teal: '#00a372',
    cyan: '#007fd4',
    black: '#000',
    white: '#fff',
    gray: {
      100: '#f8f9fa',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#6c757d',
      700: '#495057',
      800: '#343a40',
      900: '#212529',
    },
  },
};

const conf = {
  tenant: 'carreon',
  brand: 'carreon',
  headline: 'Proteja agora o que você tem de mais valioso: sua saúde, sua reputação e seu futuro profissional',
  site: 'Carreon Advocacia',
  domain: 'carreon.adv.br',
  author: 'Evandro Carreon',
  title: 'Evandro Carreon Advocacia',
  headlinetitle: 'Direito Médico, Digital e da Saúde',
  endpoint: 'link API',
  logo: 'logo_512.png',
  favicon: 'https://www.carreon.adv.br/favicon.ico',
  contato: {
    email: 'evandro@carreon.adv.br',
    telefone: '(11) 99195-7391',
    phone: '5511991957391',
    endereco: 'Av. Paulista, São Paulo-SP',
    whatsapp: '(11) 99195-7391',
    phonenumber: '5511991957391',
    linkedin: 'evandrocarreonadv',
    instagram: 'evandrocarreonadv',
    site: 'https://www.carreon.adv.br',
  },
  images,
  colors,
};

const meta = {
  type: 'Organization',
  imageLink: `https://www.${conf.contato.site}/logo.png`,
  imageType: 'image/png',
  imageWidth: 301,
  imageHeight: 77,
  description:
    'A Evandro Carreon Advocacia é especializada em Direito Médico e Direito Digital, com atuação em todo o Brasil há mais de 20 anos. Atuamos em ações contra o SUS, medicamentos de alto custo, defesa de pacientes e médicos, direitos dos autistas e consultoria jurídica em saúde, e assessoria em direito digital.',
  keywords:
    'Direito Médico Brasil, Ações contra SUS, Medicamentos alto custo, Judicialização da saúde, Defesa de médicos, Compliance para médicos, Terapias multidisciplinares, Ações contra planos, Direito Digital especializado, Compliance para clínicas, Liminares de saúde, Assessoria jurídica médica, Assistência para médicos, Direitos dos autistas, Consultoria jurídica saúde, Carreon Advocacia, Evandro Carreon, Direito Médico, Direito Digital, Direito da Saúde',
  author: conf.author,
  robots: 'index, follow',
};
conf.meta = meta;

const bio = [
  {
    type: ui.social.whatsapp,
    href: `https://wa.me/${conf.contato.phonenumber}`,
    text: 'Fale conosco pelo WhatsApp',
  },
  {
    type: ui.social.phone.reg,
    href: `tel:+${conf.contato.phonenumber}`,
    text: 'Ligue para nós',
  },
  {
    type: ui.social.email,
    href: `mailto:${conf.contato.email}`,
    text: 'Envie-nos um e-mail',
  },
  {
    type: ui.social.linkedin,
    href: `https://www.linkedin.com/in/${conf.contato.linkedin}`,
    text: 'Conecte-se ao nosso LinkedIn',
  },
  {
    type: ui.social.instagram,
    href: `https://www.instagram.com/${conf.contato.instagram}`,
    text: 'Siga nosso Instagram',
  },
  {
    type: ui.social.site,
    href: conf.contato.site,
    text: 'Visite nosso site',
  },
];

conf.bio = bio;

const menu = {
  navbar: [
    {
      name: 'home',
      text: 'Home',
      showText: true,
      tooltip: 'Página inicial',
      path: '/',
      icon: ui.misc.house,
      toggle: false,
      setSection: true,
      section: 'home',
      addClass: '',
      onlyLogged: false,
    },
    {
      name: 'menu',
      text: 'Menu',
      showText: false,
      tooltip: 'Menu',
      icon: ui.misc.menu,
      toggle: true,
      setSection: false,
      addClass: '',
      onlyLogged: false,
    },
    {
      name: 'restricted',
      text: 'Área Restrita',
      showText: true,
      tooltip: 'Área Restrita',
      textLogged: 'Área Restrita',
      textUnlogged: 'Login',
      icon: ui.user.lock,
      toggle: false,
      setSection: false,
      addClass: '',
      path: '/app',
      onlyLogged: false,
    },
    {
      name: 'logout',
      text: 'Logout',
      showText: true,
      tooltip: 'Logout',
      icon: ui.misc.doorout,
      toggle: false,
      setSection: false,
      addClass: '',
      path: '/logout',
      onlyLogged: true,
    },
  ],
};

export { conf, menu };
