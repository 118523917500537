import React from 'react';
import { conf, LinkButton } from '../config/imports';
import '../assets/css/bio.css';

export default function Bio() {
  const lines = () => {
    const items = conf.bio;
    return items.map((item, index) => {
      return (
        <LinkButton
          key={index}
          item={item}
          text={item.text}
          clsnm='line bordered d-flex flex-row align-items-center fs-10 txt-500'
          iconClass='svg12'
        />
      );
    });
  };

  return (
    <div className='wrapper'>
      <div className='cont-centered cont-400'>
        <img
          src={conf.images.logo}
          className='mb-5 w-60 wpmax-300'
          alt='logo'
        />

        {lines()}
      </div>
    </div>
  );
}
