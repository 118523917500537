import React from 'react';
import { ContainerBox } from '../config/imports';

export default function Privacidade() {
  return (
    <div>
      <ContainerBox
        main={
          <div className=''>
            <div className='container-1100 p-3'>
              <div className='p-5'>
                <h4 className='my-5 fc-gold fw-600 '>
                  Termos de uso e Privacidade
                </h4>

                <h5 className='mt-5 mb-3 fc-gold'>Proteção de Dados e LGPD</h5>

                <p>
                  No Carreon Advocacia, tratamos sua privacidade com seriedade.
                  As informações fornecidas serão usadas exclusivamente para
                  atender à sua solicitação jurídica e serão armazenadas apenas
                  pelo tempo necessário. Em conformidade com a LGPD, você pode
                  solicitar a exclusão dos seus dados a qualquer momento.
                </p>

                <h5 className='mt-5 mb-3 fc-gold'>Introdução</h5>

                <p>
                  O escritório Carreon Advocacia, sob responsabilidade de seu
                  proprietário Evandro Cesar Carreon, advogado devidamente
                  incrito na OAB/SP 212.015, está comprometido em proteger a
                  privacidade e os dados pessoais de seus usuários. Esta
                  Política de Privacidade esclarece como coletamos, usamos,
                  armazenamos e protegemos as informações dos visitantes do
                  nosso site, conforme exigido pela Lei Geral de Proteção de
                  Dados (LGPD - Lei nº 13.709/2018).
                </p>

                <h5 className='mt-5 mb-3 fc-gold'>Coleta e Tratamento de Dados</h5>

                <p>
                  Coletamos dados pessoais fornecidos voluntariamente pelos
                  usuários, como nome, e-mail e telefone, através de formulários
                  de contato ou ao se cadastrar em nossa newsletter. Esses dados
                  são utilizados para responder às suas solicitações, oferecer
                  conteúdos jurídicos relevantes e melhorar nossos serviços.
                </p>

                <p>
                  Dados técnicos como endereço IP, tipo de navegador e tempo de
                  visita também podem ser coletados automaticamente para fins
                  estatísticos e de segurança.
                </p>

                <h5 className='mt-5 mb-3 fc-gold'>Finalidade do Tratamento</h5>

                <p>
                  Os dados coletados serão utilizados exclusivamente para as
                  finalidades descritas abaixo:
                </p>

                <ul>
                  <li>Responder a consultas enviadas por meio do site;</li>
                  <li>
                    Enviar materiais informativos e atualizações legais, com
                    consentimento prévio;
                  </li>
                  <li>Melhorar a experiência do usuário em nosso site;</li>
                  <li>Cumprir obrigações legais e regulatórias.</li>
                </ul>

                <h5 className='mt-5 mb-3 fc-gold'>Compartilhamento de Dados</h5>

                <p>
                  Seus dados pessoais não serão compartilhados com terceiros,
                  exceto em casos de:
                </p>

                <ul>
                  <li>Cumprimento de obrigações legais ou regulatórias;</li>
                  <li>
                    Prestação de serviços por parceiros, sempre respeitando as
                    normas da LGPD;
                  </li>
                  <li>
                    Em caso de determinação judicial ou solicitação por
                    autoridades competentes.
                  </li>
                </ul>

                <h5 className='mt-5 mb-3 fc-gold'>Segurança dos Dados</h5>

                <p>
                  Implementamos medidas técnicas e administrativas para proteger
                  os dados pessoais contra acessos não autorizados, perda
                  acidental ou violações de segurança. Contudo, nenhum sistema é
                  completamente seguro, e em caso de qualquer incidente de
                  segurança que possa comprometer seus dados, notificaremos os
                  usuários e a Autoridade Nacional de Proteção de Dados (ANPD)
                  conforme exigido pela LGPD.
                </p>

                <h5 className='mt-5 mb-3 fc-gold'>Direitos dos Usuários</h5>

                <p>Os usuários têm direito de:</p>

                <ul>
                  <li>
                    Solicitar acesso, correção ou exclusão de seus dados
                    pessoais;
                  </li>
                  <li>Revogar o consentimento para o tratamento de dados;</li>
                  <li>
                    Solicitar a portabilidade de seus dados, conforme previsto
                    pela LGPD;
                  </li>
                  <li>
                    Opôr-se ao tratamento de dados em situações específicas.
                  </li>
                </ul>

                <p>
                  Esses direitos podem ser exercidos através do e-mail{' '}
                  <a href='mailto:evandro@carreon.adv.br'>
                    evandro@carreon.adv.br
                  </a>
                  .
                </p>

                <h5 className='mt-5 mb-3 fc-gold'>Cookies</h5>

                <p>
                  Nosso site utiliza cookies para otimizar a navegação e
                  personalizar conteúdos. Ao acessar nosso site, você poderá
                  gerenciar suas preferências de cookies ou desativá-los
                  diretamente no seu navegador.
                </p>

                <h5 className='mt-5 mb-3 fc-gold'>Alterações à Política de Privacidade</h5>

                <p>
                  Reservamo-nos o direito de alterar esta Política de
                  Privacidade a qualquer momento, mediante aviso prévio no site.
                  Recomendamos a revisão periódica desta página para se manter
                  atualizado sobre como protegemos suas informações.
                </p>

                <h5 className='mt-5 mb-3 fc-gold'>Contato</h5>

                <p>
                  Em caso de dúvidas sobre esta Política de Privacidade ou sobre
                  o tratamento de seus dados pessoais, entre em contato conosco
                  através do e-mail{' '}
                  <a href='mailto:evandro@carreon.adv.br'>
                    evandro@carreon.adv.br
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        }
        navbar={true}
        sidebar={false}
        footer={true}
        overlayMain={false}
        // classMain='bgsaude'
      />
    </div>
  );
}
