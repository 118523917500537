const base = 'https://g3c32193a94f6bf-bewook.adb.sa-saopaulo-1.oraclecloudapps.com/ords/carreon/api/';
const host= 'https://g3c32193a94f6bf-bewook.adb.sa-saopaulo-1.oraclecloudapps.com/ords/bwhost/api/';
const gate = 'https://api.bewook.com/carreon/';

const endpoint = {
  leads: {
    create: `leads/c/`,
    read: `leads/r/`,
    delete: `leads/d/`,
  },
  login: `login/`,
  logout: `logout/`,
  telegram: {
    notify_admin: `telegram/api/notify_admin/`,
  },
  mail: {
    otp: `mail/otp/`,
    lead: `mail/lead/`,
  }

}

export {
  base, host, gate, endpoint
}