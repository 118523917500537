import axios from 'axios';
import { base, host, gate } from '../config/imports';

export const defaults = {
  headers: {
    Authorization: '',
  },
};

export async function post(endpoint, body, locBase = true, contentJson = true) {
  let content = contentJson ? 'application/json' : 'multipart/form-data';
  let location = locBase ? base : host;
  const api = axios.create({
    baseURL: location,
    headers: {
      'Content-Type': content,
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  api.defaults.headers.Authorization = token;
  response = await api.post(endpoint, {...body, tenant: 'CARREON', token});
  return response;
}

export async function postGate(endpoint, body) {
  const api = axios.create({
    baseURL: gate,
    headers: {
      'Content-Type':  'application/json',
    },
  });
  let response;

  response = await api.post(endpoint, body);
  return response;
}

export async function get(endpoint, locBase = true) {
  let location = locBase ? base : host;
  const api = axios.create({
    baseURL: location,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  if (token) {
    api.defaults.headers.Authorization = token;
  }
  response = await api.get(endpoint);
  return response;
}
