import React from 'react';
import { NavBar, SideBar, Footer } from '../config/imports';

export default function ContainerBox({
  main,
  navbar = false,
  sidebar = false,
  footer = false,
  overlayMain = false,
  classMain = '',
}) {
  return (
    <div className='containerbox'>
      <>
        {navbar ? <NavBar /> : ''}
        <div className={classMain}>
          <main className={overlayMain ? `py-3 bw-bg-overlay` : 'py-3'}>
            {sidebar ? <SideBar /> : ''}
            <div
              className={
                sidebar ? 'containerbox-main-right' : 'containerbox-main'
              }
            >
              {main}
            </div>
          </main>
        </div>
      </>
      {footer ? <Footer /> : null}
    </div>
  );
}
