import React from 'react';
import { conf } from '../config/imports';

function Page500() {
  return (
    <div className='App'>
      <header className='wrapper text-center'>
        <a href='/'>
          <img
            src={conf.images.logo_fe_hz_color}
            className='home-logo'
            alt='logo'
          />
        </a>
        <p>Não autorizado</p>
      </header>
    </div>
  );
}

export default Page500;
