import {conf} from './conf';

export function metaInfo(conf) {
  return {
    type: conf.meta.type,
    description: conf.meta.description,
    keywords: conf.meta.keywords,
    author: conf.meta.author,
    open: {
      title:  `${conf.title} - ${conf.headlinetitle}`,
      description: conf.meta.description,
      image: conf.meta.imageLink,
      imageWidth: conf.meta.imageWidth,
      imageHeight: conf.meta.imageHeight,
      imageType: conf.meta.imageType,
      url: `https://www.${conf.domain}`,
      type: 'website',
    },
    twitter: {
      card: 'summary_large_image',
      title:  `${conf.title} - ${conf.headlinetitle}`,
      description: conf.meta.description,
      image: conf.meta.imageLink,
    },
    robots: conf.meta.robots,
  };
}

const META = metaInfo(conf);

export function createSchema(schema) {
  const scriptTag = document.getElementById('meta-json-ld') || document.createElement('script');
  scriptTag.type = 'application/ld+json';
  scriptTag.id = 'meta-json-ld';
  scriptTag.text = JSON.stringify(schema);
  document.head.appendChild(scriptTag);
  return () => {
    document.head.removeChild(scriptTag);
  };
}

export function defineSchema() {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': META.type || 'Organization',
    name: `${conf.title} - ${conf.headlinetitle}`,
    alternateName: conf.tenant,
    description: conf.description,
    image: conf.contato.site + '/' + conf.logo,
    url: conf.contato.site,
    email: conf.contato.email,
    telephone: `+55${conf.contato.phone}`,
    areaServed: 'BR',
    brand: conf.brand,
    slogan: conf.headline,
   
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'São Paulo-SP',
      addressRegion: 'SP',
      postalCode: '01310-000',
      streetAddress: 'Bela Vista',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: 99,
      bestRating: 100,
      ratingCount: 5891,
    },
  };

  return createSchema(jsonLd);
}

export function defineMeta() {
  document.title =  `${conf.title} - ${conf.headlinetitle}`;

  const currentYear = new Date().getFullYear();

  const setElement = (selector, type, attributes) => {
    const element =
      document.querySelector(selector) || document.createElement(type);
    Object.entries(attributes).forEach(([key, value]) =>
      element.setAttribute(key, value),
    );
    document.head.appendChild(element);
  };

  setElement('link[rel="icon"]', 'link', {
    rel: 'icon',
    type: 'image/x-icon',
    href: conf.favicon,
  });
  setElement('link[rel="apple-touch-icon"]', 'link', {
    rel: 'apple-touch-icon',
    type: 'image/x-icon',
    href: `/img/brand/${conf.brand}/logo512.png`,
  });

  const metaTags = [
    { name: 'description', content: META.description },
    { name: 'keywords', content: META.keywords },
    { name: 'author', content: META.author },
    { name: 'publisher', content: META.author },
    { name: 'robots', content: META.robots },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'googlebot', content: 'index, follow' },
    { name: 'rating', content: 'general' },
    { name: 'revisit-after', content: '1 days' },
    { name: 'language', content: 'pt-BR' },
    { name: 'country', content: 'Brazil' },
    { name: 'geo.region', content: 'BR-SP' },
    { name: 'copyright', content: `${currentYear} ${conf.title}` },
    {
      name: 'robots',
      content:
        'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
    },
    { name: 'Content-Type', content: 'text/html; charset=utf-8' },
  ];

  metaTags.forEach((tag) =>
    setElement(`meta[name="${tag.name}"]`, 'meta', tag),
  );

  setElement('link[rel="canonical"]', 'link', {
    rel: 'canonical',
    href: META.open.url,
  });

  const ogTags = [
    { property: 'og:title', content: META.open.title },
    { property: 'og:description', content: META.open.description },
    { property: 'og:image', content: META.open.image },
    { property: 'og:image:width', content: META.open.imageWidth },
    { property: 'og:image:height', content: META.open.imageHeight },
    { property: 'og:image:type', content: META.open.imageType },
    { property: 'og:url', content: META.open.url },
    { property: 'og:type', content: META.open.type },
    { property: 'og:locale', content: 'pt_BR' },
    { property: 'og:site_name', content: conf.meta.title },
  ];

  ogTags.forEach((tag) =>
    setElement(`meta[property="${tag.property}"]`, 'meta', tag),
  );

  const twitterTags = [
    { name: 'twitter:card', content: META.twitter.card },
    { name: 'twitter:title', content: META.twitter.title },
    { name: 'twitter:description', content: META.twitter.description },
    { name: 'twitter:image', content: META.twitter.image },
  ];

  twitterTags.forEach((tag) =>
    setElement(`meta[name="${tag.name}"]`, 'meta', tag),
  );
}

export function addMeta(name, content) {
  const meta = document.createElement('meta');
  meta.name = name;
  meta.content = content;
  document.head.appendChild(meta);
}
